

























































import {Component, Prop, Vue} from "vue-property-decorator";
import {Address} from "@/data/model/Address";

@Component({
  components: {},
})

export default class AddressForm extends Vue {
  @Prop({required: true})
  public value!: Array<Address>

  @Prop({required: false, default: false})
  public simplified!: boolean

  created() {
    this.value.forEach(el => el.addressline ? el.addressline[3] : '')
  }

  get notEmptyRule() {
    return [
      (v: string) => !!v || this.$t('form.notEmpty'),
    ]
  }

}
